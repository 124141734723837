// Config
$circle-size: 3.2rem;

.btn-loading {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 5rem;
  padding-right: 5rem;

  span {
    display: inline-block;
    position: relative;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .spin {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3rem;
    height: #{$circle-size * 0.7};
    width: #{$circle-size * 0.7};
    border: 0.3rem solid rgba(255, 255, 255, 0.6);
    border-top-color: rgba($color-white, 0.1);
    border-radius: 50%;
    animation: loading-btn--fade-in 0.33s ease, loading-btn--rotation 0.66s linear 0s infinite;
    opacity: 0;
    padding: 0;
  }

  transition: all 0.33s ease-in-out;

  &-pending {
    pointer-events: none;

    .spin {
      opacity: 1;
    }
  }
}

/**
 * Animations
 */

@keyframes loading-btn--fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes loading-btn--rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/**
 * Optical stuff - has nothing todo with button animation.
 */

// .state-list {
//   margin-bottom: 12px;

//   .loading-btn-wrapper {
//     background: repeating-linear-gradient(45deg, #fff, #fff 10px, #f0f0f0 10px, #f0f0f0 20px);
//   }
// }

// .loading-btn-wrapper {
//   & + & {
//     margin-left: 8px;
//   }
// }
