.textbox {
  font-size: 1.6rem;
  line-height: 2rem;
  @include medium;
  color: $color-black;
  width: 100%;
  display: block;
  border: 0.1rem solid $color-orange;
  background-color: $color-white;
  color: #ffffff;
  appearance: none;
  border-radius: 4rem;
  padding: 2.4rem 3rem;
  color: $color-black;
  transition: border-color ease 0.3s;
  box-sizing: border-box;
  letter-spacing: 0.1rem;

  &::placeholder{ 
    opacity: .4;
  }

  &:disabled {
    background: #f9f9f9;
    color: #999999;
    cursor: not-allowed;
  }

  &.selected,
  &:focus,
  &:hover {
    color: $color-black;
    border-color: $color-orange;
    outline: none;
  }

  &::placeholder {
    color: $color-black;
  }
}

/* Change Autocomplete styles in Chrome*/
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus {
//   border: none !important;
//   -webkit-text-fill-color: #ffffff !important;
//   background-color: $color-black !important;
//   -webkit-box-shadow: 0 0 0 100rem $color-black inset; /* background rengini değişir */
//   transition: background-color 5100s ease-in-out 0s;
// }

// @-webkit-keyframes autofill {
//   to {
//     color: #ffffff !important;
//     background: transparent;
// 	border: none !important;
//   }
// }

// input:-webkit-autofill {
// 	border: none !important;
//   -webkit-animation-name: autofill;
//   -webkit-animation-fill-mode: both;
// }
