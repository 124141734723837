.header {

  @include bigger-than-md{
      height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .main-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include md {
      padding: 2rem 0;
    }

    @include bigger-than-md {
      padding: 4rem 0;
    }
  }

  .logo {
    display: inline-flex;
    margin-right: 1rem;

    img {
      max-width: 100%;
      width: auto;
      height: auto;

      @include md {
        max-height: 6rem;
      }

      @include bigger-than-md {
        max-height: 12rem;
      }
    }
  }

  .primary-menu {
    margin-left: auto;
    margin-right: 9rem;

    @include md {
      display: none;
    }

    @include bigger-than-md {
    }
  }
}

.visible-mobile {
  @include bigger-than-md {
    display: none !important;
  }
}
