.content-section {
  padding: 6rem 0;

  .mb-content {
    margin-bottom: 6rem;
  }
}

.contact-info-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem 0;

  strong {
    font-size: 2rem;
    line-height: 2.2rem;
    @include bold;
    color: $color-blue;
    display: inline-block;
    min-width: 8rem;
  }

  > div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0 1rem;
  }

  .editor-content {
    p {
      margin-bottom: 0;
    }
    a {
      color: $color-blue;
    }
  }
}
