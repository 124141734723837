
$color-primary: red; 
$color-success: green;

.switch {
	input[type="checkbox"] {
		height: 0;
		width: 0;
		visibility: hidden;
	}

	label {
		cursor: pointer;
		text-indent: -9999px;
		width: 5rem;
		height: 2.4rem;
		background: $color-primary;
		display: block;
		border-radius: 2.4rem;
		position: relative;
	}

	label:after {
		content: '';
		position: absolute;
		top: 0.2rem;
		left: 0.2rem;
		width: 2rem;
		height: 2rem;
		background: #fff;
		border-radius: 2rem;
		transition: 0.1s;
	}

	input:checked+label {
		background: $color-success;
	}

	input:checked+label:after {
		left: calc(100% - 0.2rem);
		transform: translateX(-100%);
	}

	label:active:after {
		width: 5rem;
	}
}

.switch-box{
	width: 100%;
	font-size: 2rem;
	line-height: 2rem;
	@include bold;
	color: #3b62a7;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;
	margin-bottom: 3rem;
	padding-left: 2rem;
	user-select: none;
}

.react-switch-bg{
	background-color: #ccc !important;
}