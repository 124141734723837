.payment-section {
  padding: 10rem 0;

  @include md{
    padding: 4rem 0;
  }

  .heading-page-title {
    margin-bottom: 7rem;

    @include md{
      font-size: 3rem;
      line-height: 1.2;
      margin-bottom: 4rem;
    }
  }
}

.payment-section-main-inner {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0 10rem;

  @include md{
    flex-direction: column;
  }

  .main-side {
    flex-grow: 1;

    @include md{
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      gap: 1rem 0;
      max-width: 100%;
    }
  }
}

.payment-step-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1; 


  .line-huge {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    user-select: none;
    pointer-events: none;
    z-index: -1;
    height: 8rem;

    @include md{
      height: 4rem;
    }

    &:before {
      content: '';
      position: absolute;
      left: 2rem;
      top: 50%;
      margin-top: -0.1rem;
      height: 0.2rem;
      background-color: $color-orange;
      width: calc(100% - 4rem);

      @include md{
        width: calc(100% - 5rem);
        left: 3rem;
        height: .1rem;
      }
    }
  }

  > div:not(.line-huge) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem 0;

    @include md{
      gap: 1rem 0;
    }
  }

  .icon-box {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 0.2rem solid $color-orange;
    background-color: #f4f4f4;
    user-select: none;
    text-align: center;

    @include md{
      width: 4rem;
      height: 4rem;
      border-width: .1rem;

      img{
        width: 2.4rem;
        height: auto;
      }
    }

    &.active {
      background-color: $color-orange;
      cursor: pointer;

      img {
        opacity: 0;

        + img {
          opacity: 1;
        }
      }
    }

    img {
      transform: scale(0.8);

      + img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
      }
    }
  }

  .primary-title {
    font-size: 1.8rem;
    line-height: 2.4rem;
    @include bold;
    color: $color-orange;
    text-align: center;

    @include md{
      font-size: 1.2rem;
      line-height: 1.2;
    }
  }
}
