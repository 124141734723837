.checkbox {
  display: block;
  position: relative;
  padding-left: 3rem;
  cursor: pointer;
  user-select: none;
  font-size: 1.6rem;
  line-height: 2rem;
  @include medium;
  color: $color-blue;

  a {
    @include semibold;
    color: $color-blue;
    text-decoration: underline !important;
  }

  // &:hover {
  // 	input {
  // 		&~.checkmark {
  // 			background-color: #ccc;
  // 		}
  // 	}
  // }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    pointer-events: none;

    &:checked {
      & ~ .checkmark {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 1.8rem;
    width: 1.8rem;
    background-color: $color-white;
    border-radius: 0.2rem;
    border: 0.1rem solid $color-blue;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='Isolation Mode' viewBox='0 0 18.38 12.93'%3E%3Cpath d='M18.15.22c-.3-.3-.79-.3-1.09 0L6.13 11.08 1.32 6.35c-.3-.3-.79-.3-1.09 0s-.3.78 0 1.08l5.36 5.28c.3.3.79.3 1.09 0l11.47-11.4c.3-.3.3-.78 0-1.08-.3-.3.3.3 0 0Z' style='fill:%23f4f4f4;fill-rule:evenodd'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #699f4c;
      transition: opacity ease 0.2s;
      background-size: 12px;
    }
  }
}
