#section-categories {
  .main-inner {
    display: flex;

    @include md {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      gap: 2rem;
    }

    @include bigger-than-md {
      align-items: flex-start;
      justify-content: space-between;
      gap: 0 2.5rem;
    }
  }

  .category-card {
    @include md {
      flex-basis: calc(50% - 1rem);
    }

    @include bigger-than-md {
    }
  }
}
