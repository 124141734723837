#event-list-main {
  .section-pattern.white {
    background-color: #f4f4f4;
  }
}

.event-list-section {
}

#event-detail-main {
  background-color: #f4f4f4;
  padding-bottom: 10rem;
}

#blog-list-main {
  background-color: #f4f4f4;
}

#blog-detail-main {
  .section-pattern.white {
    background-color: #f4f4f4;
  }
}

#about-detail-main {
  .section-pattern.white {
    background-color: #f4f4f4;
  }
}

#faq-list-main {
  background-color: #f4f4f4;
}

#partnership-main {
  background-color: #f4f4f4;
}

#profile-main {
  background-color: #f4f4f4;
}

#sign-up-main {
  background-color: #f4f4f4;
}

#payment-main {
  background-color: #f4f4f4;
}



.image-box-vertical.in-about{
  float: left;
  margin-right: 3rem;
  margin-bottom: 3rem;
  img{
    margin: 0;
  }
}



.payment-step-main-container{
  width: 100%; 
  margin-top: 6rem;

  @include md{
    margin-top: 4rem;
  }

  > div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem 0;
  }

  .form-group{
    width: 90%;
    max-width: 100%;

    &.sm-size{
      width: 40rem;
    }
  }
}


.payment-step-tab-container{
  width: 100%;

  &.active-step-1{
    > div{
      &:nth-child(1){
        display: block;
      }
    }
  }
  &.active-step-2{
    > div{
      &:nth-child(2){
        display: block;
      }
    }
  }
  &.active-step-3{
    > div{
      &:nth-child(3){
        display: block;
      }
    }
  }
  &.active-step-4{
    > div{
      &:nth-child(4){
        display: block;
      }
    }
    + .form-group.btn-group{
      display: none;
    }
  }

  > div{
    display: none;
    max-width: 100%;
    width: 100%;
  }

  .form-group.sm-size{
    width: 60rem;
    max-width: 100%;
  }

  .form-group-double{
    width: 60rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0 2rem;
    max-width: 100%;
  }
}

.step-secondary-title{
  display: block;
  @include semibold;
  font-size: 2.6rem;
  line-height: 3.6rem;
  margin-bottom: 2rem;
  color: #e34e35;
  text-align: left;
  margin-left: 3rem;
}


.invoice-type-form{
  margin-left: 3rem;
}

.margin-left-3rem{
  margin-left: 3rem;
}

.pb-0{
  padding-bottom: 0 !important;
}

.pt-0{
  padding-top: 0 !important;
}

#payment-iframe{
  width: 100%;
}


.payment-response-page-section{
  width: 100%;
  text-align: center;
  padding-top: 15rem;
  padding-bottom: 20rem; 
  background-color: #f4f4f4;
  position: relative;

  .main-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem 0;
  }

  .image{
    margin-bottom: 2rem;
    width: 9rem;
    height: 9rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(#99b77f,.2);

    &.close{
      background-color: rgba($color-orange,.6);
      
      img{
        width: 3rem;
        height: 3rem;
      }
    }

    img{
      width: 5rem;
      height: 5rem;
    }
  }
}


.payment-invoice-type-form-group{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;

  .form-group.radio{
    width: unset;
    flex-grow: unset;
  }
}