#mobile-navigation {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: $color-white;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  transform: translateX(100%);
  transition: transform ease 0.3s;
  overflow-x: hidden;
  overflow-y: auto;

  .mobile-navigation-active & {
    height: 100vh;
    height: 100dvh;

    transform: translateX(0);
  }

  .nav-top {
    width: 100%;
    position: relative;

    .bg {
      width: 100%;
      height: auto;
    }

    .close-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 3rem;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      background-color: transparent;
      border: none;
      padding: 0;
      margin-top: -1.2rem;

      img {
        + img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
        }
      }
    }
  }

  .nav-body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1rem 0;
    padding-bottom: 4rem;

    a {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0 2rem;
      padding: 2rem;
      height: 6rem;
      border-bottom: 0.1rem solid $color-orange;
      font-size: 2rem;
      line-height: 2.4rem;
      @include semibold;
      color: $color-orange;

      img {
        height: auto;
        width: 3rem;
      }
    }
  }
}
