.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 3rem;
  box-sizing: border-box;
}

.modal-inner {
  position: relative;
  z-index: 2;
  background-color: $color-white;
  width: 56rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  border-radius: 6rem;
  overflow: hidden;
}

.modal-shadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: #000;
}

.modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12rem;
  background-color: $color-orange;
  padding: 0 4rem 0 4rem;
  position: relative;
  box-sizing: border-box;
  z-index: 90;

  @include sm{
    height: 9rem;
  }

  .primary-title {
    font-size: 3rem;
    line-height: 1;
    @include bold;
    color: $color-white;
    
    @include sm{
      font-size: 2rem;
    }
  }

  .section-pattern {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    transform: translateY(90%);
    pointer-events: none;
    z-index: -1;

    &:before {
      background: radial-gradient(circle at center, #e34e35 60%, transparent 0%), transparent;
      background-size: 7rem 10rem;
    }
  }
}

.modal-body {
  width: 100%;
  // height: 44rem;
  padding-top: 8rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 5rem;
  box-sizing: border-box;
  position: relative;

  @include sm{
    padding-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
  }
}

.btn-modal-close {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3rem;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  user-select: none;

  &:hover {
    img {
      &:first-of-type {
        opacity: 0.9;
      }
    }
  }

  img {
    transition: opacity ease 0.3s;
    will-change: opacity;

    + img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.modal-auth {
  &.tab-1 {
    .auth-modal-form {
      + .auth-modal-form {
        visibility: visible;
        opacity: 1;
        z-index: 10;
      }
    }
  }

  .primary-btn-huge {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 3rem;
  }

  .auth-modal-form {
    + .auth-modal-form {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
      padding-top: 7rem;
      padding-left: 4rem;
      padding-right: 4rem;
      padding-bottom: 4rem;
      box-sizing: border-box;
      height: 100%;
      background-color: $color-white;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      visibility: hidden;
      z-index: -1;
      opacity: 0;
      transition: opacity ease 0.3s, visibility ease 0.3s;

      @include sm{
        padding-top: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
      }
    }
  }
}

.auth-modal-form-result-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 3rem 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  @include medium;
  color: $color-blue;
  box-sizing: border-box;
  padding: 2rem 8rem;
  visibility: hidden;
  opacity: 0;
  transition: visibility ease 0.3s, opacity ease 0.3s;

  &.type-1 {
    position: unset;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  img {
    user-select: none;
  }
}

.btn-modal-back {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3rem;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  user-select: none;

  &:hover {
    img {
      &:first-of-type {
        opacity: 0.9;
      }
    }
  }

  img {
    transition: opacity ease 0.3s;
    will-change: opacity;

    + img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
