.faq-list-section {
  @include md {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }

  @include bigger-than-md {
    padding-top: 10rem;
    padding-bottom: 5rem;
  }

  .main-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3rem 0;
  }

  .primary-title {
    @include semibold;
    color: $color-blue;
    margin-bottom: 2rem;

    @include md {
      font-size: 1.6rem;
      line-height: 2rem;
    }
    @include bigger-than-md {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }
}

.heading-2{
  @include bold;
  color: $color-blue; 

  @include md {
    font-size: 2rem;
    line-height: 3rem;
  }
  @include bigger-than-md {
    font-size: 3.6rem;
    line-height: 4rem;
  }
}