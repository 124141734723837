.valitation-text{
    font-size: 1.8rem;
    line-height: 1;
    display: block;
    margin-bottom: 2rem;
    @include medium;
    background-color: #e34e35; 
    margin-bottom: 3rem;
    padding-left: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 1rem;
    color: #fff;
    display: none;

    &.active{
        display: block;
    }
}