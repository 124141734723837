.breadcrumb {
  width: 100%;
  background-color: $color-orange;

  @include md {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }

  @include bigger-than-md {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }

  .primary-title {
    @include bold;
    color: $color-white;
    display: block;
    text-align: center;

    @include md {
      font-size: 3rem;
      line-height: 4rem;
    }

    @include bigger-than-md {
      font-size: 5rem;
      line-height: 6rem;
    }
  }
}
