.event-detail-content-box {
  width: 100%;
  display: flex;
  font-size: 1.4rem;
  line-height: 2rem;
  @include medium;
  color: $color-blue;

  @include md {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 2rem 0;
  }

  @include bigger-than-md {
    align-items: flex-start;
    justify-content: flex-start;
  }

  > div {
    &:nth-child(1) {
      flex-basis: 30%;
    }

    &:nth-child(2) {
      flex-grow: 1;
      @include md {
      }

      @include bigger-than-md {
        flex-basis: 70%;
        padding-left: 3rem;
      }
    }
  }

  .primary-title {
    @include bold;
    color: $color-orange;

    @include md {
      font-size: 3.6rem;
      line-height: 4.4rem;

      br {
        display: none;
      }
    }

    @include bigger-than-md {
      font-size: 5rem;
      line-height: 6rem;
    }
  }

  p {
    margin-bottom: 2rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
