.section-blog-title {
  background-color: #f4f4f4;

  @include md {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }

  @include bigger-than-md {
    padding-top: 10rem;
    padding-bottom: 5rem;
  }

  .main-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0 3rem;

    @include md {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    @include bigger-than-md {
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1rem 0;
    }
  }

  .primary-badge {
    display: inline-block;
    padding: 1.5rem 3rem;
    border-radius: 2rem;
    background-color: $color-orange;
    color: $color-white;

    @include md {
      font-size: 2rem;
      line-height: 3rem;
      margin-bottom: 2rem;
    }
    @include bigger-than-md {
      font-size: 3rem;
      line-height: 4rem;
    }
  }

  .primary-title {
    @include semibold;
    color: $color-blue;

    @include md {
      font-size: 3rem;
      line-height: 4rem;
    }
    @include bigger-than-md {
      font-size: 4rem;
      line-height: 5rem;
    }
  }

  .desc {
    @include regular;
    color: $color-blue;

    @include md {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    @include bigger-than-md {
      font-size: 1.4rem;
      line-height: 2rem;
    }

    b {
      @include semibold;
    }
  }
}
