.event-search-form{
    width: 100%;
    position: relative;

    .textbox{
        padding-right: 14rem;
    }

    .btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 1.8rem;
        padding: 1.8rem 4rem;
        right: .8rem;
    }

}