.event-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: $color-blue;
  @include bold;
  padding-top: 6rem;
  padding-bottom: 4rem;
  border-bottom: 0.1rem solid rgba($color-black, 0.1);

  @include md-lg {
    gap: 4rem;
  }

  @include bigger-than-lg {
    gap: 6rem;
  }

  .card-main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 4rem 0;

    > div {
      &:first-of-type {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }

      &:last-of-type {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        @include md {
          flex-direction: column;
          gap: 2rem 2rem;
        }

        @include bigger-than-md {
          gap: 2rem;
        }
      }
    }
  }

  .date-tag {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    line-height: 1;
    color: $color-white;
    @include bold;
    background-color: $color-orange;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.3);
    padding-top: 1.5rem;
    box-sizing: border-box;
    text-align: center;

    @include md {
      font-size: 1.6rem;
      width: 10rem;
      min-width: 10rem;
      height: 12rem;
      border-radius: 1.5rem;
    }

    @include bigger-than-md {
      font-size: 2rem;
      width: 12rem;
      min-width: 12rem;
      height: 14rem;
      border-radius: 3rem;
    }

    strong {
      line-height: 1;
      @include bold;
      color: $color-white;

      @include md {
        font-size: 3rem;
      }

      @include bigger-than-md {
        font-size: 5rem;
      }
    }

    .day {
      font-size: 1.2rem;
      margin-top: auto;
      @include medium;
      border-top: 0.1rem solid #fff;
      width: 100%;
      padding: 1.5rem 0;
    }
  }

  .main-huge {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // gap: 4rem 0;

    @include md {
      padding-left: 2rem;
    }

    @include bigger-than-md {
      padding-left: 5rem;
    }

    .top-huge {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3rem;
    }
  }

  .time-with-icon {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }

  .primary-title {
    font-size: 3rem;
    line-height: 3.6rem;
    color: $color-orange;
  }

  .company {
    margin-top: 1rem;
    font-size: 1.8rem;
  }

  .btn-group {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
  }

  .price-badge {
    font-size: 2rem;
    padding: 1.5rem 1rem;
    min-width: 10rem;
    text-align: center;
    background-color: $color-white;
    border-radius: 3rem;
  }

  .category-card {
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.2);

    @include md {
      display: none;
    }

    @include md-lg {
      width: 24rem;
      min-width: 24rem;
      height: 24rem;
      border-radius: 4rem;
    }

    @include bigger-than-lg {
      width: 31rem;
      min-width: 31rem;
      height: 31rem;
    }
  }
}
