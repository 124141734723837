.event-list-section {
  background-color: #f4f4f4;

  @include md {
    padding-top: 4rem;
    padding-bottom: 10rem;
  }

  @include bigger-than-md {
    padding-top: 8rem;
    padding-bottom: 14rem;
  }
}

.event-list-section-main {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  @include sm {
    flex-direction: column;
    align-items: stretch;

    .margin-top-small-in-mobile{
      margin-top: 3rem;
    }
  }

  @include md {
    gap: 0 4rem;
  }

  @include bigger-than-md {
    gap: 0 6rem;
  }

  > div{
    &:last-child{
      flex-grow: 1;
    }
  }

  .list-container {
    flex-grow: 1;
  }
}
