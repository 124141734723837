.form-group.radio {
    display: block;
    position: relative;
    padding-left: 3rem;
    cursor: pointer;
    user-select: none;
    font-size: 1.6rem;
    line-height: 2rem;
    @include medium;
    color: $color-blue;
  
    a {
      @include semibold;
      color: $color-blue;
      text-decoration: underline !important;
    }
  
    // &:hover {
    // 	input {
    // 		&~.checkmark {
    // 			background-color: #ccc;
    // 		}
    // 	}
    // }
  
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      pointer-events: none;
  
      &:checked {
        & ~ .checkmark {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  
    .checkmark {
      position: absolute;
      top: 42%;
      transform: translateY(-50%);
      left: 0;
      height: 1.8rem;
      width: 1.8rem;
      background-color: $color-white;
      border-radius: 50%;
      border: 0.1rem solid $color-blue;
      overflow: hidden;
  
      &:after {
        content: '';
        position: absolute;
        opacity: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; 
        background-color: $color-orange;
        transition: opacity ease 0.2s;
        background-size: 12px;
      }
    }
  }
  