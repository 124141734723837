.user-dropdown-huge{
    position: relative;
    z-index: 20;

    &:hover{
        .user-dropdown-menu{
            opacity: 1;
            visibility: visible;
        }
    }
}

.user-dropdown-menu{
    background-color: $color-blue;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2rem 0;
    padding: 12rem 3.5rem 3.5rem 3.5rem;
    border-radius: 2rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity ease .3s, visibility ease .3s;

    &.show{

    }

    a, 
    button{
        font-size: 1.6rem;
        line-height: 2rem;
        @include semibold;
        color: $color-white;
        white-space: nowrap;
        transition: opacity ease .3s;
        background-color: transparent;
        border: none;
        cursor: pointer;
        
        &:hover{
            opacity: .8;
        }
    }
}

