.heading-3 {
  @include bold;
  color: $color-blue;
  display: block;
  margin-bottom: 3rem;

  @include md {
    font-size: 3rem;
    line-height: 3.4rem;
  }

  @include bigger-than-md {
    font-size: 4rem;
    line-height: 4.4rem;
  }
}

.heading-page-title {
  @include bold;
  color: $color-orange;
  display: block;
  margin-bottom: 3rem;

  @include md {
    font-size: 4rem;
    line-height: 4.4rem;
  }

  @include bigger-than-md {
    font-size: 5rem;
    line-height: 4.4rem;
  }
}
