.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  line-height: 2rem;
  @include bold;
  color: $color-white;
  background-color: $color-blue;
  border-radius: 3rem;
  padding: 1rem 2rem;
  gap: 1rem;

  span {
    padding-top: 1px;
  }
}
