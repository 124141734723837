.section-about-detail {
  @include md {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }

  @include bigger-than-md {
    padding-top: 6rem;
    padding-bottom: 5rem;
  }

  .main-inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0 4rem;

    @include sm {
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      gap: 4rem 0;
    }
  }

  .editor-content{
    ul{
      float: left;
      font-size: 2rem;
    }
  }
}

.image-box-vertical {
  display: inline-block;
  position: relative;

  @include sm {
    width: 100%;
  }

  @include bigger-than-sm {
    min-width: 40rem;
  }

  &:before {
    content: '';
    width: 100%;
    padding-top: calc(calc(3 / 2) * 100%);
    display: block;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }
}
