.primary-menu {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0 3rem;

  li {
    display: inline-flex;
  }

  a {
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 1rem 1rem;
    @include bold;
    color: $color-blue;
    white-space: nowrap;
    transition: color ease 0.3s;

    &:hover {
      color: $color-yellow;
    }
  }
}
