.label {
  display: block;
  @include semibold;
  font-size: 2rem;
  line-height: 2.6rem;
  margin-bottom: 1rem;
  color: $color-orange;
  text-align: left;
  margin-left: 3rem;
}
