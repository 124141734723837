.btn {
  @include medium;
  color: $color-white;
  padding: 1.5rem 4rem;
  transition: background-color ease 0.3s;
  white-space: nowrap;
  border: none;
  cursor: pointer;

  @include md {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  @include bigger-than-md {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  img {
    margin-left: 1rem;
  }
}

span.btn{
  cursor: default;
}

.btn-rounded {
  border-radius: 3rem;
}

.btn-yellow {
  color: $color-white;
  background-color: $color-yellow;

  &:hover {
    background-color: rgba($color-yellow, 0.9);
  }
}

.btn-orange {
  color: $color-white;
  background-color: $color-orange;

  &:hover {
    background-color: rgba($color-orange, 0.9);
  }
}

.btn-blue {
  color: $color-white;
  background-color: $color-blue;

  &:hover {
    background-color: rgba($color-blue, 0.9);
  }
}

.btn-width-arrow {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  @include md {
    gap: 1rem;
  }

  @include bigger-than-md {
    gap: 2rem;
  }

  &:hover {
    .text {
      background-color: $color-white;
      color: $color-black;
    }
    .icon {
      transform: translateX(0.5rem);
    }
  }

  .text {
    border: 0.2rem solid $color-white;
    border-radius: 4rem;
    transition: background-color ease 0.3s, color ease 0.3s;

    @include md {
      padding: 1.2rem 3.2rem;
    }

    @include bigger-than-md {
      padding: 2.4rem 6.4rem;
    }
  }

  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: transform ease 0.3s;

    img {
      &:first-of-type {
        @include md {
          width: 5rem;
          height: 5rem;
        }
      }
    }

    img + img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      @include md {
        width: auto;
        height: 1.6rem;
      }
    }
  }
}

.btn-info-link {
  font-size: 1.7rem;
  line-height: 2.4rem;
  @include semibold;
  color: $color-blue;
  line-height: 1;
  border: none;
  background-color: transparent;
  outline: none;

  &.ml-space {
    margin-left: 3rem;
  }
}

button.btn-info-link {
  cursor: pointer;
}
