$transition: ease 0.3s;
$edtrBorderColor: $color-blue;
$tableEvenTrBg: $color-blue;

.editor-content {
  font-size: 1.6rem;
  line-height: 2.4rem;
  @include regular;
  color: $color-blue;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // color: $textColor;
    display: block;
    @extend .heading-3;

    @include md {
      margin-bottom: 1.5rem;
    }

    @include bigger-than-md {
      margin-bottom: 2rem;
    }
  }

  p {
    @include md {
      margin-bottom: 2rem;
    }

    @include bigger-than-md {
      margin-bottom: 3rem;
    }
  }

  a {
    color: $color-orange;
    transition: color $transition;
    @include semibold;

    &:hover {
      color: $color-yellow;
    }
  }

  strong,
  b {
    @include bold;
  }

  img {
    max-width: 100%;

    @include md {
      margin: 2rem 0;
    }

    @include bigger-than-md {
      margin: 2.8rem 0;
    }
  }

  ul,
  ol {
    @include md {
      margin: 1.8rem 0;
    }

    @include bigger-than-md {
      margin: 3.2rem 0;
    }

    li {
      position: relative;

      @include md {
        margin-bottom: 1rem;
        padding-left: 2rem;
      }

      @include bigger-than-md {
        margin-bottom: 1.6rem;
        padding-left: 2.4rem;
      }

      &:before {
        content: '';
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        background-color: $color-blue;
        position: absolute;
        top: 0.7rem;

        @include md {
          left: 0.6rem;
        }

        @include bigger-than-md {
          left: 1rem;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .table-responsive {
    width: 100%;
    overflow-x: auto;
  }

  table {
    text-align: left;
    border: 0.1rem solid $edtrBorderColor;

    @include md {
      margin-top: 2rem;
    }

    @include bigger-than-md {
      margin-top: 4rem;
    }
  }

  tr {
    background-color: $color-white;
    &:nth-child(even) {
      background-color: $tableEvenTrBg;
    }

    border-bottom: 0.1rem solid $edtrBorderColor;

    &:last-child {
      border-bottom: none;
    }
  }

  td {
    width: 50%;

    @include md {
      padding: 1.2rem;
    }

    @include bigger-than-md {
      padding: 1.6rem;
    }
  }

  th {
    @include bold;

    @include md {
      padding: 1.2rem;
    }

    @include bigger-than-md {
      padding: 1.6rem;
    }
  }

  th,
  td {
    border-right: 0.1rem solid $edtrBorderColor;

    &:last-child {
      border-right: none;
    }
  }

  iframe {
    max-width: 100%;
  }

  .iframe {
    position: relative;
    padding-bottom: 56.25%;

    @include md {
      margin: 1.5rem 0;
    }

    @include bigger-than-md {
      margin: 2rem 0;
    }

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
