.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 4rem;

  &.mb-half {
    margin-bottom: 2rem;
  }

  &.m-h-auto {
    min-height: auto;
  }

  &.top {
    justify-content: flex-start;
  }

  &.center {
    align-items: center;
  }

  &.mb-0 {
    margin-bottom: 0;
  }

  &.mb-3 {
    margin-bottom: 3rem;
  }

  &.mb-2x {
    margin-bottom: 3rem;
  }

  &.mt-2x {
    margin-top: 4rem;
  }

  &.col {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .icon-huge {
    width: 100%;
    position: relative;

    .textbox {
      padding-right: 6rem;
    }

    .icon {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      outline: none;
      background-color: transparent;

      &.password {
        width: 3.4rem;
        height: 3.4rem;

        &:before {
          content: '';
          width: 0.3rem;
          height: 3.4rem;
          background-color: $color-orange;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          opacity: 0;
          transition: opacity ease 0.3s;
        }

        &.open {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
}


.form-group.custom-select{
  > div{
    width: 100%;
  }

  .select__control{
    border-radius: 4rem !important;
    border-color: #e34e35 !important;
  }

  .select__value-container{
    padding: 2.4rem 3rem !important;
  }

  .select__indicator{
    margin-right: 1.4rem;
  }

  .select__input-container,
  .select__single-value{
    @include medium;
    font-size: 1.6rem;
    line-height: 2rem;
  }
}