.btn-has-svg {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 2rem;
  @include medium;
  color: $color-white;
  position: relative;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  @include md {
    font-size: 1rem;
  }

  @include bigger-than-md {
    font-size: 1.6rem;
  }

  &:hover {
    img {
      opacity: 0.9;
    }
  }

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    white-space: nowrap;
    display: inline-flex;

    img{
      width: 4rem;
      height: 4rem;


      @include sm{
        width: 3rem;
        height: 3rem;
      }
    }
  }

  img {
    transition: opacity ease 0.3s;
    user-select: none;
    

    @include md {
      width: 6rem;
      height: 6rem;
    }

    @include bigger-than-md {
      width: 10rem;
      height: 10rem;
    }

    + img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @include md {
        width: 3rem;
        height: 1.5rem;
      }

      @include bigger-than-md {
        width: 3rem;
        height: 1.5rem;
      }
    }
  }
}
