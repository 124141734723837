.event-detail-title-box {
  color: $color-blue;
  @include bold;
  display: flex;
  width: 100%;

  @include md {
    // flex-direction: column;
    // align-items: stretch;
    // justify-content: flex-start;
    align-items: center;
    justify-content: flex-start;
    padding: 4rem 0;
    gap: 2rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  @include bigger-than-md {
    align-items: center;
    justify-content: flex-start;
    padding: 8rem 0 10rem 0;
    gap: 4rem;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem 0;
  }

  .date-tag {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    line-height: 1;
    color: $color-white;
    @include bold;
    background-color: $color-orange;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.3);
    padding-top: 1.5rem;
    box-sizing: border-box;
    text-align: center;

    @include md {
      font-size: 1.4rem;
      width: 8rem;
      min-width: 8rem;
      height: 11rem;
      border-radius: 1rem;
    }

    @include bigger-than-md {
      font-size: 2rem;
      width: 12rem;
      min-width: 12rem;
      height: 14rem;
      border-radius: 2rem;
    }

    strong {
      line-height: 1;
      @include bold;
      color: $color-white;

      @include md {
        font-size: 2.4rem;
      }

      @include bigger-than-md {
        font-size: 5rem;
      }
    }

    .day {
      font-size: 1.2rem;
      margin-top: auto;
      @include medium;
      border-top: 0.1rem solid #fff;
      width: 100%;
      padding: 1.5rem 0;
    }
  }

  .time-with-icon {
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 1rem;

    img {
      @include md {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .category-elem{
    color: $color-blue;

    @include md {
      font-size: 1.4rem;
      line-height: 2rem;
    }

    @include bigger-than-md {
      font-size: 2rem;
      line-height: 2.6rem;
    }
  }

  .primary-title {
    color: $color-orange;

    @include md {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    @include bigger-than-md {
      font-size: 3rem;
      line-height: 3.6rem;
    }
  }

  .company {
    @include md {
      font-size: 1.4rem;
    }

    @include bigger-than-md {
      font-size: 1.8rem;
    }
  }
}
