.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: $color-orange;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    .loader-container{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #f4f4f4;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
        } 