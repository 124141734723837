.footer {
  width: 100%;

  > .container {
    &:last-child {
    }
  }

  .bottom-inner-container {
    background-color: #fa9823;
  }

  .main-inner {
    display: flex;
    position: relative;

    @include md {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 6rem;
      padding-bottom: 4rem;
      gap: 4rem 0;
    }

    @include bigger-than-md {
      align-items: stretch;
      justify-content: space-between;
      padding-top: 12rem;
      padding-bottom: 10rem;
    }

    > div {
      flex-grow: 1;
      display: flex;

      @include md {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex-basis: 100%;
      }

      @include bigger-than-md {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex-basis: 25%;
        border-right: 0.1rem solid #15629d;
      }

      &:last-of-type {
        border-right: none;
      }
    }
  }

  .primary-title {
    display: block;
    @include semibold;
    color: #15629d;

    @include md {
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: 2rem;
      text-align: left;
    }

    @include bigger-than-md {
      font-size: 3rem;
      line-height: 3.4rem;
      margin-bottom: 3rem;
      text-align: center;
    }
  }

  .primary-list {
    display: flex;
    flex-direction: column;

    &.adress{
      padding-left: 1rem;

      @include md{
        padding-left: 0;
      }

      li{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        @include md{
          align-items: flex-start;
          text-align: left;
        }

      }
    }

    @include md {
      align-items: flex-start;
      justify-content: flex-start;
    }
    @include bigger-than-md {
      align-items: center;
      justify-content: flex-start;
    }

    li {
      @include md {
        margin-bottom: 0.5rem;
      }
      @include bigger-than-md {
        margin-bottom: 1rem;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    a ,li{
      font-size: 1.6rem;
      line-height: 2.4rem;
      @include medium;
      color: #15629d;
      text-align: center;
      transition: opacity ease 0.3s;
      will-change: opacity;
      display: inline-block;

      @include md {
      }
      @include bigger-than-md {
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .social-list {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 3rem;

    @include md {
    }
    @include bigger-than-md {
    }

    a {
      display: inline-flex;
    }
  }

  .bottom-inner {
    display: flex;
    color: #fff;
    @include medium;

    > div{
      &:last-of-type{
        display: inline-flex;
      }
    }

    @include md {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 2rem 0;
      padding: 2rem 0;
      font-size: 1rem;
      line-height: 1.4rem;
    }
    @include bigger-than-md {
      padding: 3rem 0;
      align-items: center;
      justify-content: space-between;
      font-size: 1.4rem;
      line-height: 2rem;
    }

    .viggo {
      b {
        display: block;
        @include bold;

        @include md {
          font-size: 1rem;
          margin-top: 0.5rem;
        }
        @include bigger-than-md {
          margin-top: 0.5rem;
          font-size: 1.4rem;
        }
      }
    }

    b {
      @include semibold;

      @include md {
        font-size: 1.3rem;
      }
      @include bigger-than-md {
        font-size: 1.6rem;
      }
    }

    img {
      @include md {
        width: 100%;
        height: auto;
      }

      @include bigger-than-md {
        height: 8rem;
        width: auto;
      }
    }
  }

  .shape-elem {
    pointer-events: none;
    position: absolute;
    &.left-top {
      @include md {
        right: 2rem;
        top: 10rem;
        width: 5rem;
        height: 5rem;
      }
      @include bigger-than-md {
        left: 0rem;
        top: 6rem;
      }
    }

    &.top-right {
      @include md {
        right: 4rem;
        bottom: 1rem;
      }
      @include bigger-than-md {
        right: 0rem;
        top: 6rem;
      }
    }

    &.bottom-center {
      @include md {
        right: 3rem;
        bottom: 40%;
      }
      @include bigger-than-md {
        bottom: 5rem;
        right: 35%;
      }
    }
  }
}
