// React Select Customize
$color-semi-light-grey: #000;
$color-dark-grey: #333;
$color-light-title-grey: #eee;
$color-lighter-red: red;
$color-red: red;
$color-dark-red: #222;

.select {
  user-select: none;
  &-container {
    width: 100%;

    &.btn-select-outline {
      @include bigger-than-sm {
        min-width: 26.5rem;
      }

      .select__value-container {
        padding: 0rem 2rem !important;
      }

      .select__control {
        border-radius: 3rem;
        white-space: nowrap;
        padding-right: 3rem !important;
        min-height: 3.8rem !important;
      }
    }
  }

  &__indicator-arrow {
    cursor: pointer; 
  } 

  &__control {
    width: 100%; 
    box-shadow: unset;
    outline: none;
    border: 0.1rem solid $color-semi-light-grey;
    box-shadow: unset !important;
    border-color: $color-semi-light-grey !important;
    min-height: 4rem !important;
    padding: 0;
    background-color: $color-white !important;
    border-radius: 0.6rem;
    box-sizing: border-box;
    position: relative;

    &--menu-is-open {
      .select__indicator-arrow {
        img {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }

    &:hover {
      border-color: $color-semi-light-grey !important;
    }
  }

  &__value-container {
    padding: 1rem 2rem !important;
    cursor: pointer;
  }

  &__placeholder,
  &__single-value {
    font-size: 1.4rem;
    line-height: 2rem;
    @include medium;
    color: $color-dark-grey !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    user-select: none;
  }

  &__single-value {
  }

  &__input-container {
    font-size: 1.4rem;
    line-height: 2rem;
    @include medium;
    color: $color-dark-grey !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  &__indicators {
  }

  &__indicator-separator {
    display: none;
  }

  &__indicators {
  }

  &__indicator-arrow {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2rem;
    user-select: none;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transform-origin: center center;
      transition: transform ease 0.3s;
    }
  }

  &__menu {
    border: 0.1rem solid $color-orange;
    box-shadow: unset !important;
    padding: 0 !important;
    border-radius: 1.5rem;
    overflow: hidden;
  }

  &__menu-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    max-height: 17.5rem !important;
  }

  &__option {
    font-size: 1.4rem !important;
    line-height: 1.6rem;
    @include regular;
    color: $color-black !important;
    border-bottom: 0.1rem solid $color-orange;
    background-color: transparent !important;
    cursor: pointer !important;
    transition: background-color ease 0.2s;
    padding: 1rem 2rem !important;

    &--is-focused {
      background-color: $color-light-title-grey !important;
    }

    &:last-child {
      border-bottom: none !important;
    }
  }

  // Multiple Styles

  &__value-container--is-multi {
    padding: 0.3rem 2rem !important;
  }

  &__multi-value {
    padding: 0.4rem 1.2rem;
    background-color: $color-orange !important;
    border-radius: 0.6rem !important;
    border: 0.1rem solid $color-red;
    margin: 0.3rem 0.8rem 0.3rem 0 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__multi-value__label {
    font-size: 1.4rem !important;
    color: $color-white !important;
    @include regular;
    line-height: 1.6rem;
    padding: 0 !important;
    order: 2;
    margin-top: .4rem;
  }

  &__multi-value__remove {
    padding-left: 0 !important;
    padding-right: 0 !important;
    order: 1;
    border-radius: 0 !important;
    margin-right: 0.4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.4rem;
    height: 1.4rem;
    background-color: transparent !important;
  }

  &__clear-indicator {
    padding: 0 !important;
    cursor: pointer;
    margin-right: 0.3rem;
  }

  &__multi-value__remove{
    &:hover{
      color: $color-white !important;
    }
  }
}

 