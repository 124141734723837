$screen-xxs: 320px !default;
$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1440px !default;
$screen-xl: 1680px !default;

// Küçük cihazlar
@mixin mini {
  // 359px ve aşağısı
  @media (max-width: 359px) {
    @content;
  }
}

@mixin xxs {
  // 639px ve aşağısı
  @media (max-width: $screen-xxs) {
    @content;
  }
}

// XSMALL
@mixin xs {
  // 639px ve aşağısı
  @media (max-width: $screen-xs) {
    @content;
  }
}

@mixin bigger-than-xs {
  @media (min-width: ($screen-xs + 1px)) {
    @content;
  }
}

@mixin xs-sm {
  @media (min-width: calc($screen-xs + 1px)) and (max-width: $screen-sm) {
    @content;
  }
}

//SMALL
@mixin sm {
  // 640px ve aşağısı
  @media (max-width: $screen-sm) {
    @content;
  }
}

@mixin bigger-than-sm {
  // 640px ve yukarısı
  @media (min-width: ($screen-sm + 1px)) {
    @content;
  }
}

@mixin sm-md {
  // 640px ile 1023px arası
  @media (min-width: ($screen-sm + 1px)) and (max-width: $screen-md) {
    @content;
  }
}

@mixin bigger-than-md {
  // 1024px ve yukarısı
  @media (min-width: ($screen-md + 1px)) {
    @content;
  }
}

@mixin md {
  // 1024px ve aşağısı
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin md-lg {
  // 1024px ile 1365px arası
  @media (min-width: ($screen-md + 1px)) and (max-width: $screen-lg) {
    @content;
  }
}

@mixin bigger-than-lg {
  // 1366px ve yukarısı
  @media (min-width: ($screen-lg + 1px)) {
    @content;
  }
}

@mixin lg {
  // 1366px ve aşağısı
  @media (max-width: $screen-lg) {
    @content;
  }
}

@mixin lg-xl {
  // 1366px ile 1679px arası
  @media (min-width: ($screen-lg + 1px)) and (max-width: $screen-xl) {
    @content;
  }
}

//XLARGE
@mixin smaller-than-xl {
  // 1679px ve aşağısı
  @media (max-width: $screen-xl) {
    @content;
  }
}

@mixin bigger-than-xl {
  // 1680px ve yukarısı
  @media (min-width: ($screen-xl + 1px)) {
    @content;
  }
}

@mixin xl {
  // 1680px ve aşağısı
  @media (max-width: $screen-xl) {
    @content;
  }
}

@mixin xs-md {
  @media (min-width: ($screen-xs + 1px)) and (max-width: $screen-md) {
    @content;
  }
}

@mixin xxs-sm {
  @media (min-width: calc($screen-xxs + 1px)) and (max-width: $screen-sm) {
    @content;
  }
}

@mixin xxs-md {
  @media (min-width: calc($screen-xxs + 1px)) and (max-width: $screen-md) {
    @content;
  }
}

@mixin bigger-than-xxs {
  // 1680px ve yukarısı
  @media (min-width: ($screen-xxs + 1px)) {
    @content;
  }
}
