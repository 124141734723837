.section-page-title {
  background-color: #f4f4f4;

  @include md {
    padding-top: 4rem;
  }

  @include bigger-than-md {
    padding-top: 10rem;
  }

  .primary-title {
    @include semibold;
    color: $color-orange;

    @include md {
      font-size: 3rem;
      line-height: 4rem;
    }
    @include bigger-than-md {
      font-size: 4rem;
      line-height: 5rem;
    }
  }
}
