.section {
  &.p-xs {
    @include md {
      padding: 4rem 0;
    }

    @include bigger-than-md {
      padding: 9rem 0;
    }
  }
}

.section-blue {
  background-color: $color-blue;
}

.section-white {
  background-color: $color-white;

  .section-title {
    color: $color-orange;
  }
}

.section-orange {
  background-color: $color-orange;
}

.section-container {
  margin-left: auto;
  margin-right: auto;

  @include md {
    width: 90%;
  }

  @include bigger-than-md {
    width: 130rem;
  }
}

.section-title {
  display: block;
  @include bold;
  color: $color-white;
  text-align: center;

  @include md {
    font-size: 4rem;
    line-height: 5rem;
    margin-bottom: 3rem;
  }

  @include bigger-than-md {
    font-size: 6rem;
    line-height: 7rem;
    margin-bottom: 5rem;
  }
}
