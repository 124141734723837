.accordion{
    width: 100%;
    margin-bottom: 5rem;
}

.accordion__button{
    @include semibold;
    color: $color-blue !important;
     box-sizing: border-box;

    @include md {
      font-size: 1.6rem;
      line-height: 2rem;
    }
    @include bigger-than-md {
      font-size: 2rem;
      line-height: 2.4rem;
    }
}

.accordion__panel{
    @extend .editor-content;

    p{
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}