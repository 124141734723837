.hero-slider-img {
  // height: 50rem;
  width: 100%;
  background-color: $color-orange;
  display: block;

  &:before {
    display: block;
    content: '';
    width: 100%;

    @include sm {
      padding-top: 40%;
    }

    @include bigger-than-sm {
      padding-top: calc(calc(2 / 5) * 100%);
    }
  }

  .img,
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
