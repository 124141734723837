/*
Font Name: Jeko
Font Folder Name: jeko

Font List:
jeko-extra-light.ttf 
jeko-thin.ttf
jeko-regular.ttf
jeko-medium.ttf
jeko-semi-bold.ttf
jeko-bold.ttf
jeko-extra-bold.ttf 
jeko-variable.ttf

-> For ttf format, you have to mention format('truetype'). For woff, format('woff')
*/

/* Jeko ExtraLight */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Jeko-ExtraLight';
  font-style: normal;
  src: url('../fonts/jeko/jeko-extra-light.ttf') format('truetype'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@mixin extralight {
  font-family: 'Jeko-ExtraLight';
}

/* Jeko Thin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Jeko-Thin';
  font-style: normal;
  src: url('../fonts/jeko/jeko-thin.ttf') format('truetype'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@mixin thin {
  font-family: 'Jeko-Thin';
}

/* Jeko Regular */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Jeko-Regular';
  font-style: normal;
  src: url('../fonts/jeko/jeko-regular.ttf') format('truetype'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@mixin regular {
  font-family: 'Jeko-Regular';
}

/* Jeko Medium */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Jeko-Medium';
  font-style: normal;
  src: url('../fonts/jeko/jeko-medium.ttf') format('truetype'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@mixin medium {
  font-family: 'Jeko-Medium';
}

/* Jeko Semibold */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Jeko-Semibold';
  font-style: normal;
  src: url('../fonts/jeko/jeko-semi-bold.ttf') format('truetype'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@mixin semibold {
  font-family: 'Jeko-Semibold';
}

/* Jeko Bold */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Jeko-Bold';
  font-style: normal;
  src: url('../fonts/jeko/jeko-bold.ttf') format('truetype'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@mixin bold {
  font-family: 'Jeko-Bold';
}

/* Jeko Extra Bold */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Jeko-ExtraBold';
  font-style: normal;
  src: url('../fonts/jeko/jeko-extra-bold.ttf') format('truetype'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@mixin extrabold {
  font-family: 'Jeko-ExtraBold';
}

/* Jeko Variable*/
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Jeko-Variable';
  font-style: normal;
  src: url('../fonts/jeko/jeko-variable.ttf') format('truetype'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@mixin variable {
  font-family: 'Jeko-Variable';
}
