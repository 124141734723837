.section-company-title-box {
  background-color: $color-orange;

  .container {
    @include sm {
      padding: 4rem 0;
    }

    @include bigger-than-sm {
      padding: 6rem 0;
    }
  }

  .info-card {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.4rem;
    line-height: 2rem;
    @include medium;
    color: $color-blue;

    @include sm {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    @include bigger-than-md {
    }

    .detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.4rem 0;
      max-width: 80rem;

      @include sm {
        padding-top: 4rem;
        align-items: center;
      }

      @include bigger-than-sm {
        padding-left: 4rem;
      }
    }

    .img {
      box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: $color-white;
      overflow: hidden;

      img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: auto;
      }

      @include sm {
        width: 16rem;
        min-width: 16rem;
        height: 16rem;
      }

      @include bigger-than-sm {
        width: 20rem;
        min-width: 20rem;
        height: 20rem;
      }
    }

    .primary-title {
      @include semibold;
      color: $color-white;

      @include md {
        font-size: 3rem;
        line-height: 4rem;
      }
      @include bigger-than-md {
        font-size: 4rem;
        line-height: 5rem;
      }
    }
  }
}
