.section-partnership-inner {
  display: flex;

  @include md {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @include bigger-than-md {
    align-items: center;
    justify-content: flex-start;
  }

  .left-side {
    display: inline-flex;

    @include bigger-than-md {
      margin-right: 10rem;
    }

    img {
      user-select: none;

      @include md {
        width: auto;
        height: 16rem;
      }
    }
  }

  .right-card {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 60rem;
    max-width: 100%;
  }

  .logo {
    display: block;
    user-select: none;

    @include md {
      margin-bottom: 2rem;
      width: 20rem;
      height: auto;
      margin-top: 6rem;
    }

    @include bigger-than-md {
      margin-bottom: 6rem;
    }
  }

  .primary-title {
    @include semibold;
    color: $color-white;

    @include md {
      font-size: 2rem;
      line-height: 2.4rem;
    }

    @include bigger-than-md {
      font-size: 3rem;
      line-height: 3.4rem;
    }
  }

  .desc {
    @include regular;
    color: $color-white;

    @include md {
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin-top: 2rem;
    }

    @include bigger-than-md {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-top: 4rem;
    }
  }

  .btn {
    @include md {
      margin-top: 2.5rem;
    }

    @include bigger-than-md {
      margin-top: 5.5rem;
    }
  }
}

.section-partnership {
  position: relative;
}

.section-partnership-img {
  display: inline-flex;
  pointer-events: none;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include md {
    top: 2rem;
  }

  @include bigger-than-md {
    top: 50%;
    transform: translateY(-50%);
    height: 80%;
  }

  img {
    width: auto;
    height: 90%;
    max-height: 90%;

    @include md {
      height: 26rem;
    }
  }
}

