html {
  @include bold;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;

  @include xxs {
    font-size: 44%;
  }

  @include bigger-than-xxs {
    // font-size: 62.5%;
    font-size: 54.5%;
  }
}

html {
  scroll-behavior: smooth;
  // max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
}

body {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  // width: 100vh;
  // background-image: linear-gradient(to right, #8360c3, #2ebf91);
  // background-image: url("../images/illustration-min.svg");
  // background-attachment: fixed;
  // background-size: 100% auto;
}

#root {
  max-width: 200rem;
  margin: 0 auto;
}

#root,
.App {
  min-height: 100vh;
}

// body * { /* assigns this style to every tag inside of your body tag */
//   font-size: 12px;
//   font-family: $font-family;
// }

// p, span, a, etc { /* you decided what tags would most likely contain text to apply that style */
//   font-size: 12px;
//   font-family: $font-family;
// }

// * { /* the worst option, applying that style to every tag */
//   font-size: 12px;
//   font-family: $font-family;
// }

// * {
//   font-size: 12px;
//   font-family: $font-family;
// }

a {
  text-decoration: none;
}

::selection {
  background-color: $color-orange;
}
