.full-image-box {
  width: 100%;
  position: relative;

  @include md {
    margin-bottom: 3rem;
  }

  @include bigger-than-md {
    margin-bottom: 5rem;
  }

  &:before {
    content: '';
    width: 100%;
    display: block;
    padding-top: calc(calc(3 / 8) * 100%);
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3rem;
  }
}
