.filter-sidebar {
  box-sizing: border-box;
  background-color: #f54d30;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.3);
  border-radius: 3rem;
  position: relative;
  overflow: hidden;
  transition: height ease 0.3s; 

  @include sm {
    width: 30rem;
    padding: 3rem 3rem 5rem 3rem;
    overflow: hidden;

    &.collapsed {
      height: 10rem;

      .btn-filter-toggle {
        .icon {
          span {
            + span {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  @include sm {
    width: 100%;
  }

  @include sm-md {
    width: 25rem;
    padding: 5rem 2rem 4rem 2rem;
  }

  @include bigger-than-md {
    width: 35rem;
    padding: 5rem 5rem 9rem 5rem;
  }

  .primary-title {
    display: block;
    font-size: 3rem;
    line-height: 3.4rem;
    @include semibold;
    color: $color-white;

    @include md {
      margin-top: 0.6rem;
    }

    @include bigger-than-md {
      margin-bottom: 4.5rem;
    }
  }

  .filter-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    font-size: 1.6rem;
    line-height: 2rem;
    @include semibold;
    color: $color-white;

    @include md {
      padding-top: 4rem;
    }

    > div {
      width: 100%;
      padding: 3rem 0;
      border-bottom: 0.1rem solid $color-white;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      gap: 2rem 0;

      &:first-child {
        padding-top: 0;
      }
    }
  }

  .btn-filter {
    margin-top: 4rem;
  }
}

.btn-filter {
  display: inline-block;
  padding: 1rem 3rem;
  font-size: 1.6rem;
  line-height: 2rem;
  @include semibold;
  color: $color-white;
  border-radius: 0.8rem;
  background-color: #3b62a5;
  border: none;
  cursor: pointer;
  text-align: center;

  @include md {
    width: 100%;
  }
}

.btn-filter-toggle {
  position: absolute;
  background-color: transparent;
  border: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 10rem;

  @include bigger-than-md {
    display: none;
  }

  .icon {
    display: inline-flex;
    width: 4rem;
    height: 4rem;
    position: absolute;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
    padding-top: 2rem;

    @include bigger-than-sm {
      display: none;
    }

    span {
      width: 4rem;
      height: 0.2rem;
      display: inline-block;
      background-color: $color-white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity ease 0.3s;

      + span {
        height: 4rem;
        width: 0.2rem;
        opacity: 0;
      }
    }
  }
}
