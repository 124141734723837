.auth-btn-list {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0 0.5rem;

  li {
    display: inline-flex;
  }
}
