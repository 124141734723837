.event-detail-sidebar {
  box-sizing: border-box;
  background-color: #f54d30;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.3);
  border-radius: 3rem;
  max-width: 100%;

  &.type-2{
    background-color: #fff;
    padding: 0;
    overflow: hidden;

    .primary-title{
      padding: 4rem 3rem;
      background-color: $color-orange;

      @include md{
        padding: 2.5rem 3rem;
      }
    }

    .sidebar-body{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 1rem 3rem;
      color: $color-blue;

      .clock{
        margin-top: -3px;

        @include md{
          width: 2rem;
          height: auto;
        }
      }

      > div{
        padding: 2rem 0;
        border-bottom: .1rem solid #cccbcb;
        &:last-child{
          border-bottom: none;
        }
      }
    }

    .event-title{
      font-size: 2rem;
      line-height: 2.4rem;
      @include semibold;
      color: $color-blue;
    }


    .btn-group{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 0;
      padding: 0 3rem;
      padding-bottom: 3rem;
      box-sizing: border-box;

      .price-badge{
        flex-grow: unset;
        font-size: 2.4rem;
        line-height: 1;
        @include semibold;
        background-color: $color-blue;
        color: $color-white;
        padding: 1.5rem 3rem;
        border-radius: 3rem;
        min-width: unset;
      }
    }

  }

  @include md {
    width: 30rem;
    padding: 2rem;
  }

  @include sm {
    width: 100%;
  }

  @include bigger-than-md {
    width: 38rem;
    padding: 3rem;
  }

  .primary-title {
    display: block;
    @include semibold;
    color: $color-white;
    margin-bottom: 2rem;

    @include md {
      font-size: 2rem;
      line-height: 2.4rem;
    }

    @include bigger-than-md {
      font-size: 3rem;
      line-height: 3.4rem;
    }
  }

  .secondary-title {
    display: block;
    @include semibold;
    color: $color-white;
    margin-bottom: 2rem;

    @include md {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @include bigger-than-md {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }

  .sidebar-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    @include semibold;
    color: $color-white;

    &.border-top-padding{
      padding-top: 2rem;
      border-top: 0.1rem solid $color-white;
    }

    @include md {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }

    @include bigger-than-md {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    > div {
      width: 100%;
      padding: 2rem 0;
      border-bottom: 0.1rem solid $color-white;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem 1rem;

      @include md {
      }

      @include bigger-than-md {
      }

      &:first-child {
        padding-top: 0;
      }
    }
  }

  .btn-group {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 2rem;
    width: 100%;

    @include md {
    }

    @include bigger-than-md {
    }
  }

  .price-badge {
    padding: 1.5rem 1rem;
    text-align: center;
    background-color: $color-white;
    border-radius: 3rem;
    flex-grow: 1;

    @include md {
      font-size: 1.6rem;
    }

    @include bigger-than-md {
      font-size: 2rem;
      min-width: 10rem;
    }
  }

  .btn {
    gap: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    @include md {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @include bigger-than-md {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
