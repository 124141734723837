.section-pattern {
  width: 100%;
  height: 5rem;
  position: relative;
  pointer-events: none;
  user-select: none;
  overflow: hidden;

  // &.hero-top {
  //   &:before {
  //     content: '';
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     width: 100%;
  //     height: 10rem;
  //     background: radial-gradient(circle at center, #d64e35 71%, transparent 0%), transparent;
  //     background-size: 9rem 10rem;
  //     background-repeat: repeat-x;
  //     background-position: left bottom;
  //   }
  // }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 10rem;
    background: radial-gradient(circle at center, $color-orange 71%, transparent 0%), transparent;
    background-size: 9rem 10rem;
    background-repeat: repeat-x;
    background-position: left bottom;
  }

  &.reverse {
    &:before {
      top: unset !important;
      bottom: 0;
    }
  }

  &.blue {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 10rem;
      background: radial-gradient(circle at center, $color-blue 71%, transparent 0%), transparent;
      background-size: 9rem 10rem;
      background-repeat: repeat-x;
      background-position: left bottom;
    }
  }

  &.white {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 10rem;
      background: radial-gradient(circle at center, $color-white 71%, transparent 0%), transparent;
      background-size: 9rem 10rem;
      background-repeat: repeat-x;
      background-position: left bottom;
    }
  }
}
