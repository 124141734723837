.counter-form {
  display: inline-flex;
  align-items: center;
  width: 20rem !important;
  display: inline-block !important;
  text-align: center;
  position: relative;

  > div {
    font-size: 2.4rem;
    user-select: none;
  }

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    background-color: $color-orange;
    font-size: 3rem;
    line-height: 1;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    color: $color-white;
    outline: none;
    

    &:first-of-type {
      left: 1rem;
      padding-bottom: 0.4rem;
    }
    &:last-of-type {
      right: 1rem;
    }
  }
}
