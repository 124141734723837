.swiper-pagination-bullet {
  opacity: 1 !important;
  outline: none !important;
  background: transparent !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
  border-radius: 50%;
  border: 0.2rem solid $color-white;
  transition: background-color ease 0.3s;
  margin: 0 !important;
  margin-right: 1.5rem !important;

  @include sm{
    width: 1.4rem !important;
    height: 1.4rem !important;
  }

  &:last-of-type {
    margin-right: 0 !important;
  }

  &.swiper-pagination-bullet-active {
    background-color: $color-white !important;
  }
}
