.blog-category-card {
    width: 100%;
    border-radius: 4rem;
    overflow: hidden;
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background-color: $color-white;
    transition: box-shadow ease 0.3s; 
    width: 34rem;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
    &:hover {
      box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.2);
    } 
  
    &:hover {
      box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.4);
      .img {
        img {
          transform: scale(1.05);
        }
      }
    }
  
    .img {
      position: relative;
      width: 100%;
      overflow: hidden;
  
      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-top: calc(calc(5 / 7) * 100%);
        background-color: $color-orange;
      }
  
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform ease 0.3s;
      }
    }
  
    .badge {
      font-size: 1.4rem;
      line-height: 1;
      @include bold;
      color: $color-white;
      padding: 1rem 2rem;
      border-radius: 3rem;
      position: absolute;
      z-index: 2;
      bottom: 2rem;
      left: 2rem;
      background-color: $color-orange;
      pointer-events: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding-top: 1.2rem;
    }
  
     
    .primary-title {
      font-size: 3rem;
      line-height: 3.4rem;
      color: $color-white;
      @include semibold;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
    }
    
  }
  