.blog-list-section {
  @include md {
    padding-top: 5rem;
    padding-bottom: 10rem;
  }

  @include bigger-than-md {
    padding-top: 10rem;
    padding-bottom: 20rem;
  }

  .list-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include md {
      gap: 5rem 3rem;
    }
    @include bigger-than-md {
      gap: 10rem 6rem;
    }
  }

  .blog-card, 
  .blog-category-card {

    @include sm{
      width: 100%;
    }

    @include sm-md {
      flex-basis: calc(50% - 1.5rem);
    }

    @include md-lg{
      flex-basis: calc(33.33% - 4rem);
    }

    @include bigger-than-lg {
      flex-basis: calc(25% - 4.5rem);
    }
  }
}
