.partnership-section {
  padding: 10rem 0;
  position: relative;

  .desc {
    @include regular;
    color: $color-black;
    width: 80rem;
    max-width: 100%;

    @include md {
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin-top: 2rem;
    }

    @include bigger-than-md {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-top: 4rem;
    }
  }

}
