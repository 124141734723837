#hero {
  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 3rem;
    box-sizing: border-box;
    
    @include md{
      bottom: 2rem;
    }

    @include bigger-than-md{
      bottom: 4.5rem;
    }
  }
}
