.event-detail-tag-box {
  width: 100%;
  padding: 3rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 4rem;
  font-size: 1.4rem;
  line-height: 2rem;
  @include semibold;
  color: $color-blue;

  + .event-detail-tag-box {
    margin-top: 0 !important;
  }

  @include md {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 1rem 0;
    margin-top: 2rem;
  }

  @include bigger-than-md {
    margin-top: 6rem;
  }

  .primary-title {
    font-size: 2rem;
    line-height: 3rem;
    @include bold;
    color: $color-orange;
    white-space: nowrap;
    min-width: 14rem;

    @include md {
    }

    @include bigger-than-md {
    }
  }

  > div{
    padding-top: .4rem;
  }
}
