.two-col-form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;

  @include md {
    gap: 4rem 0rem;
  }

  @include bigger-than-md {
    gap: 4rem 6rem;
  }
}

.form-container {
  width: 100%;

  &.mt {
    margin-top: 6rem;
  }

  &.sign-up {
    .btn {
      width: unset;
      padding-left: 6rem;
      padding-right: 6rem;
    }

    .form-group.btn-group {
      flex-basis: 100%;
      align-items: flex-end;
    }
  }

  &.partnership {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    .form-group:not(.checkbox) {
      @include sm {
        flex-basis: 100%;
        width: 100%;
      }

      @include bigger-than-sm {
        flex-basis: 60%;
        width: 60%;
      }
    }

    .btn {
      width: unset;
      padding-left: 6rem;
      padding-right: 6rem;
    }

    .form-group.btn-group {
      flex-basis: 100%;
      align-items: flex-end;
      width: 100%;

      @include bigger-than-sm {
        flex-basis: 60%;
        width: 60%;
      }
    }
  }

  .btn {
    width: 100%;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    border-radius: 4rem;
    font-size: 2rem;
  }

  .form-group {
    margin-bottom: 0;
    width: unset;
    justify-content: flex-end;

    &.full {
      flex-basis: 100% !important;
    }

    @include md {
      flex-basis: 100%;
    }

    @include bigger-than-md {
      flex-basis: calc(50% - 3rem);
    }
  }
}

.position-relative {
  position: relative;
}

.partnership-featured-image {
  position: absolute;
  pointer-events: none;
  user-select: none;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  @include sm {
    display: none;
  }

  img {
    height: auto;
    width: 22rem;
  }
}
