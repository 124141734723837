.event-detail-main-box {
  display: flex;

  @include md {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    margin-bottom: 4rem;
    gap: 2rem 0;
  }

  @include bigger-than-md {
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 10rem;
    gap: 4rem;
  }

  .img {
    border-radius: 3rem;
    overflow: hidden;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    position: relative;

    @include md {
    }

    @include bigger-than-md {
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: calc(calc(2 / 4) * 100%);
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }
}
