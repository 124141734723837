.company-info-card {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4rem;
  line-height: 2rem;
  @include medium;
  color: $color-blue;
  padding: 10rem 0;
  //   background-color: $color-white;
  border-top: 0.1rem solid #bcb9b8;
  margin-top: 4rem;
  padding-bottom: 0;

  @include md {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @include bigger-than-md {
  }

  .detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.4rem 0;
    max-width: 80rem;

    @include md {
      margin-top: 2rem;
    }

    @include bigger-than-md {
      padding-left: 4rem;
    }
  }

  .img {
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.3);
    border-radius: 3rem;
    width: 14rem;
    min-width: 14rem;
    height: 14rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include md {
    }

    @include bigger-than-md {
    }

    img{
      height: auto;
      width: auto;
      max-width: 100%;
    }
  }

  .primary-title {
    font-size: 2rem;
    line-height: 2.6rem;
    color: $color-blue;
    @include semibold;

    @include md {
    }

    @include bigger-than-md {
    }
  }

  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    padding: 0.6rem 3rem;
    font-size: 1.4rem;

    @include md {
    }

    @include bigger-than-md {
    }
  }
}
