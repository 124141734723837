.blog-card {
  width: 100%;
  border-radius: 4rem;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background-color: $color-white;
  transition: box-shadow ease 0.3s;

  &.type-1 {
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
    &:hover {
      box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.2);
    }
    .detail {
      height: 23rem;
      align-items: center;
      padding: 3.5rem 5rem 2rem 5rem;
      text-align: center;
    }
  }

  &.type-2 {
    width: 38rem;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
    &:hover {
      box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.2);
    }

    .detail {
      height: 18rem;
      align-items: center;
      padding: 3.5rem 5rem 2rem 5rem;
      text-align: center;
    }
  }

  &:hover {
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.4);
    .img {
      img {
        transform: scale(1.05);
      }
    }
  }

  .img {
    position: relative;
    width: 100%;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: calc(calc(5 / 7) * 100%);
      background-color: $color-orange;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform ease 0.3s;
    }
  }

  .badge {
    font-size: 1.4rem;
    line-height: 1;
    @include bold;
    color: $color-white;
    padding: 1rem 2rem;
    border-radius: 3rem;
    position: absolute;
    z-index: 2;
    bottom: 2rem;
    left: 2rem;
    background-color: $color-orange;
    pointer-events: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 1.2rem;
  }

  .detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 2rem 1.5rem;
    height: 25rem;
    box-sizing: border-box;
  }

  .location {
    font-size: 1.4rem;
    line-height: 2rem;
    color: $color-orange;
    @include medium;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    img {
      margin-right: 1rem;
      // margin-top: -0.5rem;
    }

    span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1;
    }
  }

  .primary-title {
    font-size: 2rem;
    line-height: 2.4rem;
    color: $color-orange;
    @include semibold;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .bottom-huge {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .info {
    font-size: 1.8rem;
    line-height: 2rem;
    @include semibold;
    color: $color-orange;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-right: 1rem;
  }

  .btn {
    font-size: 1.4rem;
    padding: 1rem 1.5rem;
    padding-top: 1.2rem;
    white-space: nowrap;
    &:hover {
      img {
        transform: translateX(0.5rem);
      }
    }
    img {
      margin-left: 0.8rem;
      transition: transform ease 0.3s;
    }
  }
}
