.category-card {
  width: 36.5rem;
  max-width: 100%;
  background-color: $color-orange;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  transition: box-shadow ease 0.3s;

  @include md {
    border-radius: 4rem;
  }

  @include bigger-than-md {
    border-radius: 8rem;
  }

  &:hover {
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.4);

    img {
      transform: scale(1.05);
    }
  }

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform ease 0.3s;
  }

  .card-title {
    color: #fff;
    @include bold;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    @include md {
      font-size: 3rem;
      line-height: 4rem;
    }

    @include bigger-than-md {
      font-size: 5rem;
      line-height: 6rem;
    }
  }

  .btn {
    position: absolute;
    left: auto;
    right: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    bottom: 1.5rem;
    z-index: 2;

    @include md {
      padding: 1rem 2rem;
    }
  }
}
